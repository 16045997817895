import * as React from "react";
import { Link, useLocation, useNavigate } from "@remix-run/react";
import { cn } from "~/lib/utils";
import { ChevronDownIcon } from "lucide-react";

interface NavItem {
    title: string;
    href: string;
    description?: string;
}

interface NavSection {
    title: string;
    items: NavItem[];
}

interface NavMenuProps {
    navSections: NavSection[];
    closeMenu: () => void;
    className?: string;
}

export function NavMenu({ navSections, closeMenu, className }: NavMenuProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const [openDropdown, setOpenDropdown] = React.useState<string | null>(null);

    const isActive = (path: string | string[]) => {
        if (typeof path === 'string') {
            if (path === '/') {
                return location.pathname === path;
            }
            return location.pathname.startsWith(path);
        }
        return path.some(p => location.pathname.startsWith(p));
    };

    const handleDropdownToggle = (dropdown: string) => {
        setOpenDropdown(openDropdown === dropdown ? null : dropdown);
    };

    const handleLinkClick = (href: string) => {
        setOpenDropdown(null);
        closeMenu();
        navigate(href);
    };

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (!(event.target as Element).closest('.nav-dropdown, nav')) {
                setOpenDropdown(null);
                closeMenu();
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [closeMenu]);

    return (
        <nav className={cn("bg-white dark:bg-zinc-900/50", className)}>
            <ul className="flex flex-col gap-2 md:flex-row items-left md:items-center justify-center space-y-1 md:space-y-0 md:space-x-1 p-2">
                <span className="block md:hidden text-sm font-semibold text-zinc-900 dark:text-zinc-300 uppercase mb-1 px-3">Menu</span>
                {navSections?.map((section) => (
                    <li key={section.title} className="relative nav-dropdown">
                        {section.items.length > 1 ? (
                            <>
                                <button
                                    onClick={() => handleDropdownToggle(section.title)}
                                    className={cn(
                                        "flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium transition-colors",
                                        "text-gray-700 dark:text-gray-200 hover:bg-emerald-50 hover:text-emerald-600 dark:hover:bg-emerald-900 dark:hover:text-emerald-400",
                                        isActive(section.items.map(item => item.href)) ? 'bg-emerald-50 text-emerald-600 dark:bg-emerald-900 dark:text-emerald-400' : ''
                                    )}
                                >
                                    <span>{section.title}</span>
                                    <ChevronDownIcon className="h-4 w-4 transition-transform duration-200" 
                                                     style={{ transform: openDropdown === section.title ? 'rotate(180deg)' : 'rotate(0deg)' }}/>
                                </button>
                                {openDropdown === section.title && (
                                    <div className={cn(
                                        "absolute left-0 top-full mt-1 w-full bg-white dark:bg-zinc-800 rounded-md shadow-lg z-10 border border-gray-200 dark:border-gray-700",
                                        section.items.length < 4 || section.items.every(item => !item.description) ? "md:w-auto" : "md:w-[600px]"
                                    )}>
                                        <ul className={cn(
                                            "p-4",
                                            section.items.length < 4 || section.items.every(item => !item.description) ? "flex flex-col space-y-2" : "grid grid-cols-1 md:grid-cols-2 gap-2"
                                        )}>
                                            {section.items.map((item) => (
                                                <li key={item.title}>
                                                    <button
                                                        onClick={() => handleLinkClick(item.href)}
                                                        className={cn(
                                                            "w-full text-left p-2 rounded-md text-sm transition-colors",
                                                            "hover:bg-emerald-50 hover:text-emerald-600 dark:hover:bg-emerald-900 dark:hover:text-emerald-400",
                                                            isActive(item.href) ? 'bg-emerald-50 text-emerald-600 dark:bg-emerald-900 dark:text-emerald-400' : 'text-gray-700 dark:text-gray-200'
                                                        )}
                                                    >
                                                        <div className="font-medium truncate">{item.title}</div>
                                                        {item.description && (
                                                            <p className="text-xs text-gray-500 dark:text-gray-400 mt-1 line-clamp-2">{item.description}</p>
                                                        )}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </>
                        ) : (
                            <Link
                                to={section.items[0].href}
                                onClick={() => {
                                    setOpenDropdown(null);
                                    closeMenu();
                                }}
                                className={cn(
                                    "px-3 py-2 rounded-md text-sm font-medium transition-colors",
                                    "text-gray-700 dark:text-gray-200 hover:bg-emerald-50 hover:text-emerald-600 dark:hover:bg-emerald-900 dark:hover:text-emerald-400",
                                    isActive(section.items[0].href) ? 'bg-emerald-50 text-emerald-600 dark:bg-emerald-900 dark:text-emerald-400' : ''
                                )}
                            >
                                {section.title}
                            </Link>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
}