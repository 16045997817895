export const AppRoutes = {
	HOME: "/",

	// public routes
	CONTACT: "/contact",
	LOGIN: "/login",
	LOGOUT: "/logout",
	SIGNUP: "/signup",
	FORGOT_PASSWORD: "/forgot-password",
	RESET_PASSWORD: "/reset-password",

	// private routes
	Dashboard: {
		Index: "/dashboard",
		Integrations: {
			Index: "/dashboard/integrations",
			New: "/dashboard/integrations/new",
			Edit: (id: string) => `/dashboard/integrations/${id}/edit`,
		},
		Connections: {
			Index: "/dashboard/connections",
			New: "/dashboard/connections/new",
			Edit: (id: string) => `/dashboard/connections/${id}/edit`,
		},
		Settings: {
			Index: "/dashboard/settings",
			Profile: "/dashboard/settings/profile",
			Password: "/dashboard/settings/password",
			Notifications: "/dashboard/settings/notifications",
			Integrations: "/dashboard/settings/integrations",
		},
		Users: "/dashboard/users",
		Profile: "/dashboard/profile",
		APIKeys: "/dashboard/api-keys",
	},

	Profile: {
		Select: "/profile/select",
		NoProfiles: "/profile/no-profiles",
	},
};
